<script>
import { useTemplateRef } from 'vue';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { getMaskImageStyle, isScreenSm } from '@@/utils/CommonUtils';
import { scrollActiveElementIntoView } from '@@/utils/Components/ComponentUtils';

/**
 * The Pill type exists so that consistent props can be passed to the <Button> components rendered
 * by the <Pills> component. In addition, a custom validator is used below to ensure that the pills
 * prop is always an array of Pill instances.
 */
export function Pill(params = {}) {
  this.badge = typeof params.badge === 'string' ? params.badge : null;
  this.href = typeof params.href === 'string' ? params.href : null;
  this.icon = typeof params.icon === 'string' ? params.icon : null;
  this.isLocked = typeof params.isLocked === 'boolean' ? params.isLocked : false;
  this.options = Array.isArray(params.options) ? params.options : null;
  this.rel = typeof params.rel === 'string' ? params.rel : null;
  this.target = typeof params.target === 'string' ? params.target : null;
  this.text = typeof params.text === 'string' ? params.text : 'Missing text!';
  this.to = typeof params.to === 'string' ? params.to : null;
}
</script>

<script setup>
const props = defineProps({
  disableHover: {
    type: Boolean,
    default: false,
  },
  isStrongButton: {
    type: Boolean,
    default: false,
  },
  pills: {
    type: Array,
    required: true,
    validator: function validator(values) {
      return Array.isArray(values) && values.every((value) => value instanceof Pill);
    },
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  selectedOption: {
    type: Number,
    default: null,
  },
  selectedPill: {
    type: Number,
    default: null,
  },
  shouldScrollActiveElementIntoView: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

const list = useTemplateRef('list');
const listItems = useTemplateRef('listItems');
const menus = useTemplateRef('menus');
const showPillDropdown = ref(null);

const canShowMenu = (index) => index === showPillDropdown.value;

const getButtonProps = (pill) => {
  const getPropsForButton = () => {
    const { href, rel, target, to } = pill;
    return { href, rel, target, to };
  };

  if (props.secondary) {
    return {
      small: true,
      type: ButtonTypes.pillSecondary,
      ...getPropsForButton(pill),
    };
  }

  return {
    type: ButtonTypes.pill,
    ...getPropsForButton(pill),
  };
};

const getCardProps = (index) => {
  const { width } = listItems.value?.[index]?.getBoundingClientRect() || {};

  if (typeof width === 'number') {
    return { style: `--card-body-min-width: ${Math.ceil(width)}px;` };
  }

  return {};
};

const getDropdownMenuStyle = (index) => {
  // The style only needs to be set for the active pill
  if (showPillDropdown.value !== index) {
    return null;
  }

  // The style only needs to be set for the last pill
  if (index !== props.pills.length - 1) {
    return null;
  }

  // The style only needs to be set on small screens (for now)
  if (!isScreenSm()) {
    return null;
  }

  const listItemWidth = listItems.value?.[index]?.getBoundingClientRect()?.width;
  let menuWidth;

  menus.value.forEach((menu) => {
    if (listItems.value[index].contains(menu)) {
      menuWidth = menu.getBoundingClientRect().width;
    }
  });

  // Reposition the menu, aligning it to the right of the list item, if the menu width is greater
  // than the list item width so that the menu for the last pill is not cropped and shown outside
  // the viewport.
  if (listItemWidth && menuWidth && menuWidth > listItemWidth) {
    const x = parseInt(menuWidth - listItemWidth) * -1;
    return { transform: `translateX(${x}px)` };
  }

  return null;
};

const getIconProps = (icon) => {
  return {
    class: 'tw-inline-block tw-w-4 tw-h-4 tw-align-middle',
    style: {
      backgroundColor: 'currentColor',
      ...getMaskImageStyle(icon),
    },
  };
};

const getPillText = (pill) => {
  const pillText = pill.text;

  if (props.pills[props.selectedPill] === pill && pill.options && props.selectedOption !== null) {
    const optionText = pill.options[props.selectedOption].text;
    return `${pillText}: ${optionText}`;
  }

  return pillText;
};

const handleScrollActiveElementIntoView = async (index) => {
  if (props.shouldScrollActiveElementIntoView) {
    const activeElement = typeof index === 'number'
      ? listItems.value[index]
      : listItems.value[props.selectedPill];
    scrollActiveElementIntoView({
      parent: list.value,
      activeElement,
    });
  }
};

const handleClick = ($event, pill, index) => {
  // Ignore the click event if a <DropdownMenu> in the current pill was closed in its click outside
  // handler. If the click were handled here then the <DropdownMenu> would be _reopened_ so that a
  // second click on the pill button wouldn't close it!
  if ($event?.originalEvent?.dropdownMenuClosed) {
    const dropdownMenu = $event.originalEvent.dropdownMenuClosed;

    if (listItems.value[index].contains(dropdownMenu)) {
      return;
    }
  }

  handleScrollActiveElementIntoView(index);

  if (pill.options) {
    // If the clicked pill has options then show/hide the dropdown, but don't select the pill
    // yet! The pill will be selected when the user selects one of the pill's options. Until
    // then the previously selected pill will remain selected.

    $event?.originalEvent?.stopImmediatePropagation();

    if (showPillDropdown.value === index) {
      showPillDropdown.value = null;
    }
    else {
      showPillDropdown.value = index;
    }

    return;
  }

  emit('click', $event, pill);
};

/**
 * When the dropdown menu is closed and a different dropdown menu isn't being shown then reset
 * the showPillDropdown value so that if the same pill is clicked again, the dropdown menu will
 * be displayed!
 */
const handleDropdownClose = (pillDropdown) => {
  if (pillDropdown === showPillDropdown.value) {
    showPillDropdown.value = null;
  }

  // Set height in timeout so menu collapse animation is visible
  window.setTimeout(() => {
    listItems.value[pillDropdown].style.height = 'auto';
  }, 250);
};

const handleDropdownOpen = (pillDropdown) => {
  const pillHeight = listItems.value[pillDropdown].getBoundingClientRect().height;
  let menuHeight;

  menus.value.forEach((menu) => {
    if (listItems.value[pillDropdown].contains(menu)) {
      menuHeight = menu.getBoundingClientRect().height;
    }
  });

  // +10 for a little extra height
  listItems.value[pillDropdown].style.height = `${pillHeight + menuHeight + 10}px`;
};

const handleSelectOption = ($event, pill, option) => {
  showPillDropdown.value = null;
  emit('click', $event, pill, option);
};

watch(() => props.selectedPill, () => handleScrollActiveElementIntoView(props.selectedPill));

onMounted(() => handleScrollActiveElementIntoView());
</script>

<template>
  <ul
    ref="list"
    class="tw-inline-block tw-whitespace-nowrap"
  >
    <li
      v-for="(pill, index) in pills"
      :key="pill.text"
      ref="listItems"
      :class="[
        'tw-inline-block tw-mr-1.5 last:tw-mr-0',
        $style.listItem,
        index === selectedPill ? $style.listItemActive : '',
      ]"
    >
      <Button
        v-bind="getButtonProps(pill)"
        :active="index === selectedPill"
        :disable-hover="disableHover"
        :is-strong-button="isStrongButton"
        @click="($event) => handleClick($event, pill, index)"
      >
        <span
          v-if="pill.icon"
          v-bind="getIconProps(pill.icon)"
        />
        <Component
          :is="pill.badge"
          v-if="pill.badge"
          :extra-small="true"
        />
        {{ getPillText(pill) }}
        <font-awesome-icon
          v-if="pill.isLocked"
          :class="[
            'all-access-color tw-text-sm tw-pl-0.5',
            $style.listItemLockIcon,
          ]"
          icon="lock"
        />
        <font-awesome-icon
          v-else-if="pill.options"
          :class="[
            'tw-w-5 tw-transition-transform',
            canShowMenu(index) ? 'tw-rotate-180' : '',
          ]"
          :icon="['far', 'chevron-circle-down']"
        />
      </Button>
      <DropdownMenu
        v-if="pill.options"
        :card-props="getCardProps(index)"
        :is-dropdown-absolute="true"
        :menu-style="() => getDropdownMenuStyle(index)"
        :show-menu="canShowMenu(index)"
        @close="handleDropdownClose(index)"
        @open="(props) => handleDropdownOpen(index, props)"
      >
        <template #menu>
          <ul ref="menus">
            <li
              v-for="(option, optionIndex) in pill.options"
              :key="option.text"
              :class="[
                'tw-px-2.5 tw-py-1 first:tw-pt-2 last:tw-pb-2',
                'tw-cursor-pointer',
                $style.dropdownListItem,
                index === selectedPill && optionIndex === selectedOption ? $style.dropdownListItemActive : '',
              ]"
              @click="($event) => handleSelectOption($event, pill, option)"
            >
              <font-awesome-icon
                v-if="option.isLocked"
                :class="[
                  'all-access-color tw-text-sm tw-pl-0.5',
                  $style.dropdownListItemLockIcon,
                ]"
                icon="lock"
              />
              <span
                v-else-if="option.icon"
                v-bind="getIconProps(option.icon)"
              />
              {{ option.text }}
            </li>
          </ul>
        </template>
      </DropdownMenu>
    </li>
  </ul>
</template>

<style module>
.listItemActive .listItemLockIcon,
.listItem:hover .listItemLockIcon {
  color: white;
}

.dropdownListItem:hover,
.dropdownListItemActive {
  background-color: var(--light-blue);
  color: white;
}

.dropdownListItemActive .dropdownListItemLockIcon,
.dropdownListItem:hover .dropdownListItemLockIcon {
  color: white;
}
</style>
